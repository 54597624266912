import React from "react";
import typing from "../../../utils/typing";
import style from "./style.module.scss";

export default class Intro extends React.Component {
  componentDidMount() {
    const el = document.querySelector("#title2");
    const typer = new typing(
      el,
      ["ingenieros", "diseñadores", "animadores", "psicólogos", "pedagogos"],
      1000
    );
  }
  render() {
    return (
      <div className={style.team}>
        <p>
          Contamos con un equipo multidisciplinario formado por <br />
          <strong id="title2">educación</strong>
        </p>
        <div className={style.buttonContainer}>
          <img
            className={style.buttonLinesLeft}
            src={process.env.PUBLIC_URL + "/lines1.png"}
            alt=""
          />
          <a>Conócenos</a>
          <img
            className={style.buttonLinesRight}
            src={process.env.PUBLIC_URL + "/lines1.png"}
            alt=""
          />
        </div>
      </div>
    );
  }
}
