import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FirebaseProvider from "./components/FirebaseReact/Provider";
import Home from "./containers/Home";
import Blog from "./containers/Blog";
import Post from "./containers/Post";
import "./index.css";

function App() {
  return (
    <div className="App">
      <FirebaseProvider>
        <Router>
          <Switch>
            <Route
              path="/no-te-infectes"
              component={() => {
                window.location =
                  "https://pixframe-studios.itch.io/no-te-infectes";
                return null;
              }}
            />
            <Route path="/" component={Home} />
            <Route path="/blog" component={Blog} />
            <Route path="/post/:id" component={Post} />
          </Switch>
        </Router>
      </FirebaseProvider>
    </div>
  );
}

export default App;
