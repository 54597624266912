import React from "react";
import FirebaseContext from "./CreateContext";
import Firebase from "../../services/Firebase";

export default class FirebaseProvider extends React.Component {
  constructor(props) {
    super(props);
    this.app = new Firebase(this.setState);
    this.state = {
      user: null
    };
  }
  // Aqui se podria crear automaticamente la instancia de firebase App
  // si el value del provider cambia invoca un rerender?
  render() {
    return (
      <FirebaseContext.Provider value={this.app}>
        {this.props.children}
      </FirebaseContext.Provider>
    );
  }
}
