import React from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import noop from "lodash/noop";
import style from "./style.module.scss";

class EditorCompoenent extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.post) {
      const { id, title, content } = this.props.post;
      const contentState = convertFromRaw(JSON.parse(content));
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        id,
        title,
        editorState,
        editing: false
      };
    } else {
      this.state = {
        title: "",
        editorState: EditorState.createEmpty(),
        editing: false
      };
    }
  }

  onChange = editorState => {
    this.setState({
      editorState
    });
  };

  onEdit = () => {
    this.setState({ editing: true });
  };

  onSave = () => {
    this.setState({ editing: false });
    const content = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    this.props.onSave({
      id: this.state.id,
      title: this.state.title,
      content
    });
  };

  render() {
    const { editorState, editing } = this.state;
    return (
      <div className={style.container}>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onChange}
          wrapperClassName={editing ? style.wrapper : style.wrapper_noeditable}
          toolbarClassName={editing ? style.toolbar : style.toolbar_noeditable}
          editorClassName={editing ? style.editor : style.editor_noeditable}
          placeholder="Este es mi post"
          readOnly={!editing}
        />
        <div className={style.buttons}>
          <button
            onClick={this.onEdit}
            className={style.button}
            style={{ display: this.state.editing ? "none" : "block" }}
          >
            edit
          </button>
          <button
            onClick={this.onSave}
            className={style.button}
            style={{ display: this.state.editing ? "block" : "none" }}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

EditorCompoenent.defaultProps = {
  onSave: noop
};
export default EditorCompoenent;
