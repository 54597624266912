import React from "react";
import style from "./style.module.scss";

export default function About() {
  return (
    <div className={style.contact}>
      <div className={style.contactButton}>
        <img
          className={style.arrow}
          src={process.env.PUBLIC_URL + "/arrow.png"}
          alt=""
        />
        <p>¿Tienes un proyecto en mente?</p>
        <p>¡Te ayudamos a construirlo!</p>
        <div>
          <img
            className={style.buttonLinesLeft}
            src={process.env.PUBLIC_URL + "/lines1.png"}
            alt=""
          />
          <a
            href="https://form.jotform.co/51438204334852"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contáctanos
          </a>
          <img
            className={style.buttonLinesRight}
            src={process.env.PUBLIC_URL + "/lines1.png"}
            alt=""
          />
        </div>
      </div>

      <div className={style.contactFooter} id="contact">
        <img
          className={style.wave}
          src={process.env.PUBLIC_URL + "/wave2.png"}
          alt=""
        />
        <img
          className={style.logo}
          src={process.env.PUBLIC_URL + "/logo-white.png"}
          alt=""
        />

        <div>
          <h3>Contáctanos</h3>
          <p>contacto@pixframestudios.com</p>
          <p>Teléfono 6389 5300</p>
          <h3>Pixframe Studios SAPI de CV</h3>
          <p>Av. de la Alborada No. 136 Parques del Pedregal CP 01410, CDMX</p>
          <p>www.pixframestudios.com</p>
        </div>
      </div>
    </div>
  );
}
