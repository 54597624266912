import React from "react";
import typing from "../../../utils/typing";
import style from "./style.module.scss";

export default class Intro extends React.Component {
  componentDidMount() {
    const el = document.querySelector("#title1");
    const typer = new typing(
      el,
      ["Innovación", "Educación", "Tecnología"],
      1000
    );
  }
  render() {
    return (
      <div className={style.intro} id="intro">
        <div className={style.introText}>
          <h2 id="title1">Innovación</h2>
          <p>
            En Pixframe estamos apasionados por desarrollar soluciones que{" "}
            <strong>transformen la educación.</strong>
          </p>
        </div>
        <div
          className={style.pixel}
          style={{
            position: "absolute",
            background: "#FBB03C",
            width: 30,
            height: 30,
            top: 250,
            left: 80
          }}
        />
        <div
          className={style.pixel}
          style={{
            position: "absolute",
            background: "#FFF",
            width: 40,
            height: 40,
            top: 430,
            left: 260
          }}
        />

        <div
          className={style.pixel}
          style={{
            position: "absolute",
            background: "#FBB03C",
            width: 25,
            height: 25,
            top: 100,
            right: 260
          }}
        />
        <div
          className={style.pixel}
          style={{
            position: "absolute",
            background: "#FFF",
            width: 40,
            height: 40,
            top: 240,
            right: 160
          }}
        />
        <div
          className={style.pixel}
          style={{
            position: "absolute",
            background: "#FBB03C",
            width: 25,
            height: 25,
            top: 420,
            right: 260
          }}
        />
        <img
          className={style.wave}
          src={process.env.PUBLIC_URL + "/wave.svg"}
          alt=""
        />
      </div>
    );
  }
}
