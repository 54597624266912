import React from "react";
import style from "./style.module.scss";

const data = {
  partners: [
    { name: "unicef", src: process.env.PUBLIC_URL + "/unicef-logo.png" },
    { name: "wayra", src: process.env.PUBLIC_URL + "/wayra-logo.png" },
    { name: "nyx", src: process.env.PUBLIC_URL + "/nyx-logo.png" }
  ]
};

export default function About() {
  return (
    <div className={style.partners} id="partners">
      <div className={style.wrapper}>
        <p>
          Nuestros socios inversionistas confían en que{" "}
          <strong>nuestra misión es trascendental para el futuro</strong> de
          nuestros usuarios.
        </p>
        <div className={style.partnerslist}>
          {data.partners.map(partner => (
            <div className={style.partner}>
              <img src={partner.src} alt={partner.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
