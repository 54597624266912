import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import pick from "lodash/pick";

const firebaseConfig = {
  apiKey: "AIzaSyBlGws-lAgT7MhO1ntdFi3EnGCAAe3-aTg",
  authDomain: "pixframe-af01c.firebaseapp.com",
  databaseURL: "https://pixframe-af01c.firebaseio.com",
  projectId: "pixframe-af01c",
  storageBucket: "pixframe-af01c.appspot.com",
  messagingSenderId: "928709825209",
  appId: "1:928709825209:web:f778dce010037041"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.store = app.firestore();
    this.storage = app.storage().ref();
  }
  // *** Auth API ***

  register = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  login = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  logout = () => this.auth.signOut();

  sendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email);

  updatePassword = password => this.auth.currentUser.updatePassword(password);

  getPosts = async id => {
    try {
      const request = await this.store.collection("posts").get();
      const data = request.docs.map(postSnap => ({
        ...postSnap.data(),
        id: postSnap.id
      }));
      return data;
    } catch (error) {
      throw error;
    }
  };
  getPost = async id => {
    try {
      const request = await this.store
        .collection("posts")
        .doc(id)
        .get();
      const data = { ...request.data(), id: request.id };
      return data;
    } catch (error) {
      throw error;
    }
  };
  createPost = async data => {
    try {
      await this.store.collection("posts").add(data);
    } catch (error) {
      throw error;
    }
  };
  updatePost = async data => {
    try {
      const clean = pick(data, ["title", "content"]);
      await this.store
        .collection("posts")
        .doc(data.id)
        .update(clean);
    } catch (error) {
      throw error;
    }
  };
}

export default Firebase;
