import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faCog, faAward } from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.css";

const data = {
  title: "Nuestros Servicios",
  description:
    "Apoyamos a nuestros clientes desde el diseño hasta el lanzamiento de sus proyectos.",
  helperText: "Conoce lo que podemos hacer por ti:",
  services: [
    {
      icon: faRocket,
      title: "¿Tienes una idea?",
      description:
        "Te asesoramos para diseñar proyectos innovadores que aprovechen las tecnologías actuales.",
      image: { alt: "", src: process.env.PUBLIC_URL + "/service1.png" }
    },
    {
      icon: faCog,
      title: "¿Quieres implementar tecnología?",
      description:
        "Desarrollamos e implementamos sistemas, aplicaciones y videojuegos que enriquecen las experiencias de aprendizaje.",
      image: { alt: "", src: process.env.PUBLIC_URL + "/service2.png" }
    },
    {
      icon: faAward,
      title: "¿Necesitas que te enseñen?",
      description:
        "Ofrecemos diseño instruccional, cursos en línea y capacitaciones presenciales para lograr tus objetivos de formación.",
      image: { alt: "", src: process.env.PUBLIC_URL + "/service3.png" }
    }
  ]
};

export default function Services(props) {
  const [selected, select] = useState(0);
  return (
    <div className={style.container} id="services">
      <h2>{data.title}</h2>
      <p>{data.description}</p>
      <p>{data.helperText}</p>
      <div className={style.services}>
        <div className={style.list}>
          {data.services.map((service, index) => (
            <div
              onClick={() => select(index)}
              className={style.service}
              style={{
                background: index === selected ? "rgba(0, 0, 0, .05)" : "none"
              }}
            >
              <div
                style={{
                  background: index === selected ? "#FBB03C" : "#317CFB"
                }}
              >
                <FontAwesomeIcon icon={service.icon} />
              </div>
              <div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={style.image}>
          <img
            src={data.services[selected].image.src}
            alt={data.services[selected].image.alt}
          />
        </div>
      </div>
    </div>
  );
}
