import React from "react";
import SocialHeader from "./SocialHeader";
import NavBar from "./NavBar";
import Intro from "./Intro";
import About from "./About";
import Team from "./Team";
import Partners from "./Partners";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "./Footer";
import style from "./style.module.scss";

export default function Home() {
  return (
    <div className={style.home}>
      <NavBar />
      <SocialHeader />
      <Intro />
      <About />
      <Team />
      <Partners />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}
