import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import style from "./style.module.css";

export default function SocialHeader(props) {
  return (
    <div className={style.socialHeader}>
      <div>
        <a
          href="https://www.facebook.com/pixframestudios/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          href="https://www.twitter.com/pixframestudios/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://mx.linkedin.com/company/pixframe-studios/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a
          href="https://www.instagram.com/pixframestudios/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <button style={{ display: "none" }}>Español</button>
        <button style={{ display: "none" }}>English</button>
      </div>
    </div>
  );
}
