import React from "react";
import style from "./style.module.scss";

const data = {
  logoSrc: process.env.PUBLIC_URL + "/pixframe-logo.png",
  logoAlt: ""
};

export default function About() {
  return (
    <div className={style.about} id="about">
      <div className={style.logoContainer}>
        <img className={style.logo} src={data.logoSrc} alt={data.logoAlt} />
      </div>
      <div className={style.textContainer}>
        <h2>¿Quiénes somos?</h2>
        <p>
          Pixframe Studios es una empresa mexicana que tiene como principal
          objetivo <strong>transformar</strong> la <strong>educación</strong> de
          niños, jóvenes y adultos a través de la <strong>innovación</strong> y
          la <strong>tecnología.</strong>
        </p>
        <div className={style.stats}>
          <div className={style.stat}>
            <div>
              <span>38</span>
              <span>+</span>
            </div>
            <span>CLIENTES FELICES</span>
          </div>

          <div className={style.stat}>
            <div>
              <span>45</span>
              <span>+</span>
            </div>
            <span>PROYECTOS COMPLETADOS</span>
          </div>
        </div>
      </div>
    </div>
  );
}
