import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.css";

const data = {
  logoSrc: process.env.PUBLIC_URL + "/logo-white.png",
  logoTwoSrc: process.env.PUBLIC_URL + "/pixframe-original-logo.png",
  logoAlt: "Pixframe",
  buttons: [
    { label: "Inicio", target: "intro" },
    { label: "Conócenos", target: "about" },
    { label: "Servicios", target: "services" },
    { label: "Contacto", target: "contact" }
  ]
};

export default function NavBar(props) {
  const [scroll, setScroll] = useState(0);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setScroll(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const scrollTo = button => {
    setOpen(false);
    if (button.target === "intro") {
      return window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    const target = document.getElementById(button.target);
    const top = target ? target.offsetTop - 60 : 0;
    window.scroll({
      top,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <div
      className={style.navbar}
      style={{
        background: scroll > 0 ? "#FFF" : "#317CFB",
        borderColor: scroll > 0 ? "rgba(0, 0, 0, .1)" : "#317CFB",
        color: scroll > 0 ? "#317CFB" : "#FFF"
      }}
    >
      <div className={style.logoContainer}>
        <picture>
          <source
            srcset={process.env.PUBLIC_URL + "/logos/logo-white.webp"}
            type="image/webp"
          />
          <source
            srcset={process.env.PUBLIC_URL + "/logos/logo-white.jp2"}
            type="image/jp2"
          />
          <img
            srcset={process.env.PUBLIC_URL + "/logos/logo-white.png"}
            alt="Pixframe"
            className={style.logo}
            style={{ display: scroll > 0 ? "none" : "block" }}
          />
        </picture>
        <picture>
          <source
            srcset={
              process.env.PUBLIC_URL + "/logos/pixframe-original-logo.webp"
            }
            type="image/webp"
          />
          <source
            srcset={
              process.env.PUBLIC_URL + "/logos/pixframe-original-logo.jp2"
            }
            type="image/jp2"
          />
          <img
            srcset={
              process.env.PUBLIC_URL + "/logos/pixframe-original-logo.png"
            }
            alt="Pixframe"
            className={style.logo}
            style={{ display: scroll > 0 ? "block" : "none" }}
          />
        </picture>
      </div>
      <div className={style.buttonsContainer}>
        {data.buttons.map(button => (
          <button
            className={style.button}
            href="#"
            onClick={() => scrollTo(button)}
          >
            {button.label}
          </button>
        ))}
      </div>
      <div
        className={style.responsiveButtons}
        style={{ display: isOpen ? "" : "none" }}
      >
        {data.buttons.map(button => (
          <button
            className={style.button}
            href="#"
            onClick={() => scrollTo(button)}
          >
            {button.label}
          </button>
        ))}
      </div>
      <button
        className={style.responsiveMenuButton}
        onClick={() => setOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
    </div>
  );
}
