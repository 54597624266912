import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faComment,
  faClock,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss";
import Editor from "../../components/Editor";
import FirebaseConsumer from "../../components/FirebaseReact/Consumer";

class PageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null
    };
  }
  componentDidMount() {
    this.getPost(this.props.match.params.id);
  }
  onSave = post => {
    if (post.id) {
      this.props.firebase.updatePost(post);
    } else {
      this.props.firebase.createPost(post);
    }
  };
  getPost = id => {
    this.props.firebase.getPost(id).then(post => this.setState({ post }));
  };
  render() {
    const { post } = this.state;
    if (!post) {
      return null;
    }

    return (
      <div className={style.createPostContainer}>
        <div className={style.banner}>
          <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80" />
          <div className={style.bannerWrapper}>
            <div to={`/post/${post.id}`} className={style.post}>
              <div className={style.likes}>
                <FontAwesomeIcon icon={faHeart} />
                <span>{post.likes}</span>
              </div>
              <div>
                <div className={style.title}>{post.title}</div>

                <div className={style.bottomSection}>
                  <div>
                    <FontAwesomeIcon icon={faUser} />
                    <span>{post.author}</span>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faClock} />
                    <span>{post.date}</span>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faComment} />
                    <span>{post.comments} comentarios</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.content}>
          <Editor onSave={this.onSave} post={post} />
        </div>
      </div>
    );
  }
}

export default FirebaseConsumer(PageContainer);
