import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faComment,
  faClock,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss";
import FirebaseConsumer from "../../components/FirebaseReact/Consumer";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }
  componentDidMount() {
    this.getPosts();
  }
  getPosts = id => {
    this.props.firebase.getPosts(id).then(posts => this.setState({ posts }));
  };
  render() {
    const { posts } = this.state;

    if (!posts) {
      return null;
    }

    return (
      <div className={style.createPostContainer}>
        <div className={style.banner}>
          <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80" />
          <div className={style.bannerWrapper}>
            <h1>Blog y noticias</h1>
          </div>
        </div>
        <div className={style.postList}>
          {posts.map(post => (
            <Link to={`/post/${post.id}`} className={style.post}>
              <div className={style.likes}>
                <FontAwesomeIcon icon={faHeart} />
                <span>{post.likes}</span>
              </div>
              <div>
                <div className={style.title}>{post.title}</div>
                <div className={style.description}>{post.previewText}</div>
                <div className={style.bottomSection}>
                  <div>
                    <FontAwesomeIcon icon={faUser} />
                    <span>{post.author}</span>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faClock} />
                    <span>{post.date}</span>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faComment} />
                    <span>{post.comments} comentarios</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default FirebaseConsumer(Blog);
