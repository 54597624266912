import React from "react";
import FirebaseContext from "./CreateContext";

const wrapWithFirebaseContext = Component =>
  class firebaseConnect extends React.Component {
    render() {
      return (
        <FirebaseContext.Consumer>
          {firebase => <Component {...this.props} firebase={firebase} />}
        </FirebaseContext.Consumer>
      );
    }
  };

export default wrapWithFirebaseContext;
