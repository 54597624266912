import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import style from "./style.module.css";

export default function SocialHeader(props) {
  return (
    <div className={style.socialHeader}>
      <div>
        <p>
          © 2019 Pixframe Studios SAPI de CV. Todos los derechos reservados.
        </p>
      </div>
    </div>
  );
}
